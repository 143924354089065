import { UserProvider } from '@contexts/user';
import { SpeedInsights } from '@vercel/speed-insights/next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    person_profiles: 'always',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

const Raise: React.FC<AppProps> = ({ Component, pageProps }: any) => {
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  if (pageProps?.statusCode === 404 || pageProps?.statusCode === 500) {
    return <Component {...pageProps} />;
  }

  return (
    <>
      <PostHogProvider client={posthog}>
        <UserProvider>
          <Component {...pageProps} />
        </UserProvider>
        <SpeedInsights />
      </PostHogProvider>
    </>
  );
};

export default Raise;
