// sentryCustomIntegration.js
export class ConsoleErrorIntegration {
  static id = 'ConsoleErrorIntegration';
  name = ConsoleErrorIntegration.id;

  setupOnce(_: any, getCurrentHub: any) {
    const originalConsoleError = console.error;

    console.error = function (...args) {
      const hub = getCurrentHub();
      hub.captureMessage(args.join(' '), 'error');
      originalConsoleError.apply(console, args);
    };
  }
}
