import type { UserCookieValue } from '@contexts/user';
import { getUserFromStorage } from '@contexts/user';
import { createId } from '@paralleldrive/cuid2';
import type { Account, NotificationsUsers, User } from '@prisma/client';
import { upload } from '@vercel/blob/client';

import type { ProfileFormData } from '@components/pages/app/settings/Profile';

type ImageUploadType = 'account' | 'user';

export const signupUser = async (
  data: Pick<User, 'first_name' & 'last_name' & 'email'>,
) => {
  return fetch('/api/users', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      user: data,
    }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      return {
        status: err.status,
      };
    });
};

export const deletePaymentSource = async (id: string) => {
  return fetchWithToken(`/api/education/payment_sources/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const updateUser = async (hashid: string, user: ProfileFormData) => {
  return fetchWithToken(`/api/users/${hashid}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({
      user,
    }),
  }).then((res) => res.json());
};

export const uploadImage = async (
  file: File,
  hashid: string,
  type: ImageUploadType,
) => {
  const fileName = `images/${createId()}.png`;

  await upload(fileName, file, {
    access: 'public',
    handleUploadUrl: '/api/images',
    clientPayload: JSON.stringify({
      hashid,
      type,
    }),
  });
};

export const getFullUser = async (hashid: string) => {
  return fetchWithToken(`/api/users/${hashid}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
      return {
        status: err.status,
      };
    });
};

export const toggleFlagContribution: (hashid: string) => Promise<{
  status: number;
  data: {
    message: string;
  };
}> = async (hashid: string) => {
  return fetchWithToken(`/api/education/contributions/${hashid}/flagged`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const cancelRecurrence = async (hashid: string) => {
  return fetchWithToken(
    `/api/education/contributions/${hashid}/cancel_recurrence`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then((res) => res.json()) as Promise<{
    status: number;
    data: {
      message: string;
    };
  }>;
};

export const createPaymentSource = async (data: any) => {
  return fetchWithToken(`/api/education/payment_sources`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const manuallyVerifyPaymentSource = async (paymentSourceId: string) => {
  return fetchWithToken(`/api/education/payment_sources/account_verified`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      payment_source_id: paymentSourceId,
    }),
  }).then((res) => res.json());
};

export const getPlaidLinkToken = async (
  userHashid: string,
  paymentSourceId?: number | null,
) => {
  return fetchWithToken(`/api/education/payment_sources/create_link_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hashid: userHashid,
      ...(paymentSourceId && { payment_source_id: paymentSourceId }),
    }),
  }).then((res) => res.json());
};

export const createNewEducationAccount = async (data: any) => {
  return fetchWithToken(`/api/education/accounts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ account: data }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      return {
        status: err.status,
      };
    });
};

export const fetchUserByHashidWithManualToken = async (
  hashid: string,
  token: string,
) => {
  return fetch(`/api/users/${hashid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const fetchUser = async (hashid: string) => {
  return fetchWithToken(`/api/users/${hashid}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const updateAccount = async (
  hashid: string,
  data: Partial<Omit<Account, 'id' & 'hashid'>>,
) => {
  return fetchWithToken(`/api/education/accounts/${hashid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ account: data }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
      return {
        status: err.status,
      };
    });
};

export const fetchAccountsForLoggedInUser = async () => {
  return fetchWithToken(`/api/education/accounts`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const fetchPaymentSourcesForLoggedInUser = async () => {
  return fetchWithToken(`/api/education/payment_sources`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const updateNotificationForLoggedInUser = async (
  notificationId: number,
  data: Partial<Omit<NotificationsUsers, 'id' & 'hashid'>>,
) => {
  // path is /notifications_users/${notificationId}
  return fetchWithToken(
    `/api/education/notifications_users/${notificationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ notifications_user: data }),
    },
  ).then((res) => res.json());
};

export const deleteLoggedInUser = async () => {
  const user: UserCookieValue | undefined = getUserFromStorage();

  if (!user) throw new Error('User not found');

  return fetchWithToken(`/api/users/${user.hashid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
      return {
        status: err.status,
      };
    });
};

export const fetchNotificationsForLoggedInUser = async () => {
  const user: UserCookieValue | undefined = getUserFromStorage();

  if (!user) throw new Error('User not found');

  return fetchWithToken(`/api/users/${user.hashid}/notifications`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

export const fetchAccountByHashid = async (hashid: string) => {
  const user: UserCookieValue | undefined = getUserFromStorage();

  return fetch(`/api/education/accounts/${hashid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
    },
  }).then((res) => res.json());
};

export const createInvitationForAccount = async (
  accountHashid: string,
  data: {
    first_name: string;
    last_name: string;
    relationship: string;
    email: string;
  },
) => {
  return fetchWithToken(
    `/api/education/accounts/${accountHashid}/invitations`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ invitation: data }),
    },
  ).then((res) => res.json());
};

export const createContribution = async (data: any) => {
  return fetchWithToken('/api/education/contributions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ contribution: data }),
  }).then((res) => res.json());
};

export const subscribeToMailchimpList = async (email: string) => {
  return fetch(`/api/newsletter?email=${email}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  }).then((res) => res.json());
};

export const unsubscribeFromMailchimpList = async (email: string) => {
  return fetch(`/api/newsletter?email=${email}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  }).then((res) => res.json());
};

export const getMailchimpListSubscriptionStatus = async (email: string) => {
  return fetch(`/api/newsletter?email=${email}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
};

const fetchWithToken = (url: any, config = {} as any) => {
  const user: UserCookieValue | undefined = getUserFromStorage();

  if (!user) throw new Error('User not found');
  if (!user.token) throw new Error('User token not found');

  // Merge the provided headers with the bearer token
  const headers = {
    ...config.headers,
    Authorization: `Bearer ${user.token}`,
  };

  // Create a new configuration object with the updated headers
  const updatedConfig = {
    ...config,
    headers,
  };

  // Make the fetch call with the updated configuration
  return fetch(url, updatedConfig);
};
