import React, { createContext, useContext, useEffect, useState } from 'react';
import type { AccountFullModel } from '@customTypes/account';
import type { NotificationsUsersWithnotification } from '@customTypes/notificationsUsers';
import type { NotificationsUsers, PaymentSource } from '@prisma/client';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import {
  fetchAccountsForLoggedInUser,
  fetchNotificationsForLoggedInUser,
  fetchPaymentSourcesForLoggedInUser,
  fetchUser,
  fetchUserByHashidWithManualToken,
} from '@lib/fetchData';

interface UserProviderProps {
  children: React.ReactNode;
}

export interface UserCookieValue {
  token: string;
  avatar_url: string;
  hashid: string;
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

interface UserContextValue {
  user: UserCookieValue | undefined | null;
  logout: () => void;
  accounts: AccountFullModel[];
  paymentSources: PaymentSource[];
  notifications: NotificationsUsersWithnotification[];
  isUserLoading: boolean;
  isAccountsLoading: boolean;
  isPaymentSourcesLoading: boolean;
  isNotificationsLoading: boolean;
  refreshUser: () => void;
  refreshAccounts: () => void;
  refreshPaymentSources: () => void;
  refreshNotifications: () => void;
}

const RF_TAG = 'raise-financial';
export const RF_CALLBACK = 'rf_callback';

const protectedRoutes = ['/app', '/app/*'];

const UserContext = createContext<UserContextValue | undefined>(undefined);

export function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState(getUserFromStorage());
  const [accounts, setAccounts] = useState<AccountFullModel[]>([]);
  const [paymentSources, setPaymentSources] = useState<PaymentSource[]>([]);
  const [notifications, setNotifications] = useState<
    NotificationsUsersWithnotification[]
  >([]);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isAccountsLoading, setIsAccountsLoading] = useState(true);
  const [isPaymentSourcesLoading, setIsPaymentSourcesLoading] = useState(true);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(true);

  const [refreshIdUser, setRefreshIdUser] = useState(0);
  const [refreshIdAccounts, setRefreshIdAccounts] = useState(0);
  const [refreshIdPaymentSources, setRefreshIdPaymentSources] = useState(0);
  const [refreshIdNotifications, setRefreshIdNotifications] = useState(0);

  const refreshUser = () => setRefreshIdUser((prev) => prev + 1);
  const refreshAccounts = () => setRefreshIdAccounts((prev) => prev + 1);
  const refreshPaymentSources = () =>
    setRefreshIdPaymentSources((prev) => prev + 1);
  const refreshNotifications = () =>
    setRefreshIdNotifications((prev) => prev + 1);

  const router = useRouter();

  function logout() {
    destroyCookieAndUser();
    router.push('/');
  }

  const destroyCookieAndUser = () => {
    destroyCookie(null, RF_TAG, { path: '/' });
    setUser(undefined);
    setAccounts([]);
    setPaymentSources([]);
    setNotifications([]);
  };

  const saveUserToCookies = (user: UserCookieValue) => {
    if (!user) {
      throw new Error('Empty user received');
    }

    try {
      let _cooks = JSON.stringify({
        k: user.token,
        h: user.hashid,
        i: user.id,
        e: user.email,
        f: user.first_name,
        l: user.last_name,
        a: user.avatar_url,
      });
      _cooks = btoa(_cooks);

      setCookie(null, RF_TAG, _cooks, {
        path: '/',
      });

      setUser(user);
    } catch {
      throw new Error('Unable to save data; running on tab data');
    }
  };

  useEffect(() => {
    // check if current path is protected via regex
    const isCurrentPathProtected = protectedRoutes.some((route) => {
      return new RegExp(route).test(router.pathname);
    });

    if (isCurrentPathProtected && !user) {
      router.push('/auth/login');
      return;
    }

    const token = new URLSearchParams(window.location.search).get('token');
    const hashid = new URLSearchParams(window.location.search).get('hashid');

    if (!token || !hashid) {
      return;
    }

    setIsUserLoading(true);
    // Fetch user using token and hashid
    fetchUserByHashidWithManualToken(hashid, token)
      .then(({ data }) => {
        // Store the token and user in state
        saveUserToCookies(data);
        setUser(data);
        setIsUserLoading(false);

        const callbackUrl = localStorage.getItem(RF_CALLBACK);

        if (callbackUrl) {
          localStorage.removeItem(RF_CALLBACK);

          // Redirect to the callback URL
          window.location.href = callbackUrl;
        } else {
          // Redirect to dashboard
          router.push('/app');
        }
      })
      .catch((err) => {
        // If the API call fails, clear the token and user
        console.error(err);
        setIsUserLoading(false);
        logout();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, user]);

  useEffect(() => {
    if (!user?.email) return;

    const fetchAccounts = async () => {
      try {
        setIsAccountsLoading(true);
        const { data } = await fetchAccountsForLoggedInUser();
        setAccounts(data as AccountFullModel[]);
        setIsAccountsLoading(false);
      } catch (err) {
        console.error(err);
        setIsAccountsLoading(false);
      }
    };

    fetchAccounts();
  }, [user?.email, refreshIdAccounts]);

  useEffect(() => {
    if (!user?.email) return;

    const fetchPaymentSources = async () => {
      try {
        setIsPaymentSourcesLoading(true);
        const { data } = await fetchPaymentSourcesForLoggedInUser();
        setPaymentSources(data as PaymentSource[]);
        setIsPaymentSourcesLoading(false);
      } catch (err) {
        console.error(err);
        setIsPaymentSourcesLoading(false);
      }
    };

    fetchPaymentSources();
  }, [user?.email, refreshIdPaymentSources]);

  useEffect(() => {
    if (!user?.hashid) return;

    if (refreshIdUser === 0) return; // Don't fetch initiall as manual function above will do that

    const fetchUserData = async () => {
      try {
        setIsUserLoading(true);
        const { data } = await fetchUser(user?.hashid);
        saveUserToCookies(data);
        setUser(data);
        setIsUserLoading(false);
      } catch (err) {
        console.error(err);
        setIsUserLoading(false);
      }
    };

    fetchUserData();
  }, [user?.hashid, refreshIdUser]);

  useEffect(() => {
    if (!user?.email) return;

    const fetchNotifications = async () => {
      try {
        setIsNotificationsLoading(true);
        const { data } = await fetchNotificationsForLoggedInUser();
        setNotifications(data as NotificationsUsersWithnotification[]);
        setIsNotificationsLoading(false);
      } catch (err) {
        console.error(err);
        setIsNotificationsLoading(false);
      }
    };

    fetchNotifications();
  }, [user?.email, refreshIdNotifications]);

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        accounts,
        paymentSources,
        notifications,
        isUserLoading,
        isAccountsLoading,
        isPaymentSourcesLoading,
        isNotificationsLoading,
        refreshUser,
        refreshAccounts,
        refreshPaymentSources,
        refreshNotifications,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser(): UserContextValue {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return {
    user: context.user,
    logout: context.logout,
    accounts: context.accounts,
    paymentSources: context.paymentSources,
    notifications: context.notifications,
    isUserLoading: context.isUserLoading,
    isAccountsLoading: context.isAccountsLoading,
    isPaymentSourcesLoading: context.isPaymentSourcesLoading,
    isNotificationsLoading: context.isNotificationsLoading,
    refreshUser: context.refreshUser,
    refreshAccounts: context.refreshAccounts,
    refreshPaymentSources: context.refreshPaymentSources,
    refreshNotifications: context.refreshNotifications,
  };
}

export function getUserFromStorage() {
  try {
    const parsedCookies = parseCookies();
    const srCookies = parsedCookies[RF_TAG];
    const decodedCookies = atob(srCookies);
    const cookies = JSON.parse(decodedCookies);

    return {
      token: cookies.k,
      avatar_url: cookies.a,
      hashid: cookies.h,
      id: cookies.i,
      email: cookies.e,
      first_name: cookies.f,
      last_name: cookies.l,
    };
  } catch (err) {
    return undefined;
  }
}

export function getUserFromCookiesSSR(req: any) {
  try {
    const parsedCookies = parseCookies({ req });
    const srCookies = parsedCookies[RF_TAG];
    const decodedCookies = atob(srCookies);
    const cookies = JSON.parse(decodedCookies);

    return {
      token: cookies.k,
      avatar_url: cookies.a,
      hashid: cookies.h,
      id: cookies.i,
      email: cookies.e,
      first_name: cookies.f,
      last_name: cookies.l,
    };
  } catch (err) {
    return undefined;
  }
}
