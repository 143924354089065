var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b72c25d00535496a2a442dc7321794b80e105ce8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { ConsoleErrorIntegration } from './sentry-console.instegration';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production'
      ? process.env.NEXT_PUBLIC_SENTRY_DSN
      : undefined,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    new ConsoleErrorIntegration(),
  ],
  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/raisefinancial\.com\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});
